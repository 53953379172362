import React from 'react';

import CardWithIcon from 'components/shared/card-with-icon';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import AudiencesIcon from './images/audiences.inline.svg';
import CookielessIcon from './images/cookieless.inline.svg';
import MatchRatesIcon from './images/match-rates.inline.svg';

const items = [
  {
    icon: CookielessIcon,
    title: 'Cookieless',
    description: 'Match all first party data or CRM to Yobi’s Behavioral Graph ',
  },
  {
    icon: MatchRatesIcon,
    title: 'High Match Rates',
    description: 'Yobi averages 90% coverage in matching across all online and offline identifiers',
  },
  {
    icon: AudiencesIcon,
    title: 'Yobi Audiences',
    description:
      'Access Yobi Audiences for almost every product, service, and brand to uncover behavioral trends, loyalty, and performance. No data required.',
  },
];

const Features = () => (
  <section className="mt-20 md:mt-40">
    <Container className="text-center">
      <Heading tag="h2" size="lg">
        Access critical data needed to understand customer decision making and competitive
        performance
      </Heading>
      <div className="grid grid-cols-1 gap-8 lg:grid-cols-3 mt-11">
        {items.map(({ icon: Icon, title, description }, index) => (
          <CardWithIcon
            title={title}
            description={description}
            icon={Icon}
            key={index}
            theme="gray"
          />
        ))}
      </div>
    </Container>
  </section>
);

export default Features;
