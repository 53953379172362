import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import ArrowIcon from 'icons/arrow.inline.svg';

import CheckIcon from './images/check-icon.inline.svg';
import CheckSvg from './images/check.inline.svg';

const ItemsList = ({ className, features, Icon }) => (
  <ul className={`flex flex-col ${className}`}>
    {features.map((feature, index) => (
      <li className="flex mb-6 text-xl leading-relaxed align-baseline last:mb-0" key={index}>
        <Icon className="flex-shrink-0 block mt-2 mr-4" />
        {feature}
      </li>
    ))}
  </ul>
);

const UseCases = ({ title, items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleTabClick = (index) => () => setCurrentIndex(index);

  const wrapperStyles =
    'pt-20 lg:pt-32 pb-40 mt-32 md:mt-44 -mb-44 relative gradient-background-gray after:absolute after:-bottom-96 after:gradient-background-gray after:w-full after:h-96';

  return (
    <section className={wrapperStyles}>
      <Container>
        <Heading className="text-center" tag="h2" size="lg">
          {title}
        </Heading>

        <div className="mt-4 md:mt-12 lg:mt-24">
          <div className="grid-cols-12 md:grid gap-x-8">
            <div className="col-start-1 col-end-7 md:inline-block xl:col-end-6">
              {items.map(({ title, features }, index) => {
                const isActive = index === currentIndex;
                const bgColor = isActive
                  ? 'text-black md:gradient-background-use-cases-item md:shadow-inner-md md:text-white'
                  : 'md:bg-white';
                const hoverColor = !isActive && 'group-hover:text-primary-1';
                const titleTheme = isActive ? 'white' : 'black';
                return (
                  <Fragment key={index}>
                    <button
                      type="button"
                      className={`hidden md:flex relative w-full  items-center justify-between rounded-4xl md:pl-8 md:pr-5 lg:pl-10 lg:pr-7 py-6 mb-6 last:mb-0 cursor-pointer transition-colors duration-200 group ${bgColor}`}
                      tabIndex={0}
                      onClick={handleTabClick(index)}
                    >
                      <Heading
                        className={`transition-colors text-left duration-200 ${hoverColor}`}
                        tag="h3"
                        size="sm"
                        theme={titleTheme}
                      >
                        {title}
                      </Heading>
                      <ArrowIcon className="w-6 h-auto text-white lg:w-8 lg:h-5" />
                    </button>

                    <Heading className="pt-8 pb-6 md:hidden" tag="h3" size="sm" theme="black">
                      {title}
                    </Heading>
                    <ItemsList
                      features={features}
                      Icon={CheckIcon}
                      className="block md:hidden"
                      items={items}
                    />
                  </Fragment>
                );
              })}
            </div>
            <div className="col-start-7 sm:col-end-13">
              {items.map(({ features }, index) => {
                const hiddenStyles =
                  index !== currentIndex ? 'opacity-0 invisible h-0' : 'opacity-100 visible h-auto';
                return (
                  <ItemsList
                    features={features}
                    Icon={CheckSvg}
                    className={`${hiddenStyles} hidden md:block`}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

UseCases.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      features: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
};

export default UseCases;
