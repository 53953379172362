import React from 'react';

import Features from 'components/pages/intelligence/features';
import Profile from 'components/pages/intelligence/profile';
import Quote from 'components/shared/quote';
import RequestDemoHero from 'components/shared/request-demo-hero';
import UseCases from 'components/shared/use-cases';
import MainLayout from 'layouts/main';
import SeoMetadata from 'utils/seo-metadata';

const pageTitle = 'Intelligence';
const pageDescription =
  'Unify your first party data with Yobi to create an enterprise specific Behavioral Graph of your customers';

const quote = {
  title: `"<span class="text-primary-2">Yobi identified trends</span> that would have been
  impossible to leverage traditional sources to learn about".`,
  caption: 'Director of Analytics, Ecommerce Brand',
};

const useCases = {
  title: 'Use cases',
  items: [
    {
      title: 'Unified Customer Intelligence',
      features: [
        'Maximize analytic value by unifying a complete profile of behavior across Yobi’s dataset.',
        'Uncover causal relationships across signals driving product sales or digital engagement.',
        'Visualize a full path to purchase and create a customer journey across the online and offline world',
        'Measure the impact of advertising or other signals that impact decision making across cohorts. ',
      ],
    },
    {
      title: 'Sales Strategy',
      features: [
        'Discover and monetize unique relationships between your audience and external brands and services.',
        'Intelligence uncovers unexpected but profitable opportunities around ad sales, sponsorship, and investment.',
        'Utilize Yobi’s predictive analytics to forecast impact from potential partnerships.',
      ],
    },
    {
      title: 'Competitive Intelligence',
      features: [
        'Analyze audiences from almost every US brand, product, and service.',
        'Evaluate transactional churn and the signals driving the path to purchase.',
      ],
    },
    {
      title: 'Media Planning',
      features: [
        'Identify the most valuable media based on where your audience spends their time online and offline.',
        'Evaluate the causal or predictive impact different publishers have on driving engagement or sales for brands or services.',
        'Forecast potential media conversion and value across the omnichannel universe.',
      ],
    },
  ],
};

const Intelligence = () => (
  <MainLayout pageMetadata={SeoMetadata.intelligence}>
    <RequestDemoHero title={pageTitle} description={pageDescription} />
    <Features />
    <Profile />
    <Quote title={quote.title} caption={quote.caption} />
    <UseCases {...useCases} />
  </MainLayout>
);
export default Intelligence;
