import React from 'react';

import animationData from 'animations/intelligence/profile/diagram.json';
import Scheme from 'components/shared/scheme';

const title =
  'Resolve blindspots in first party data by creating a 360° Yobi profile of Customer behavior';

const Profile = () => (
  <Scheme
    className="mt-32 md:mt-40"
    title={title}
    titleSize="md"
    animationData={animationData}
    withTabs
  />
);

export default Profile;
