import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import QuoteIcon from 'icons/quote.inline.svg';

const Quote = ({ title, caption }) => (
  <section className="mt-24 lg:mt-32 xl:mt-40">
    <Container>
      <figure className="text-center">
        <QuoteIcon className="mx-auto w-15 h-15 sm:w-20 sm:h-20" />
        <blockquote className="mt-8">
          <Heading tag="h2" size="lg" asHTML>
            {title}
          </Heading>
        </blockquote>
        <figcaption className="mt-5">
          <p className="text-lg lg:text-xl">{caption}</p>
        </figcaption>
      </figure>
    </Container>
  </section>
);

Quote.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
};

export default Quote;
