import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/shared/button';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

const RequestDemoHero = ({ title, description, buttonText }) => (
  <section className="mt-16">
    <Container className="text-center">
      <Heading tag="h1" size="xl">
        {title}
      </Heading>
      <p className="max-w-screen-lg mx-auto mt-5 text-lg leading-normal lg:text-2xl">
        {description}
      </p>
      <Button to="#request-demo" className="mt-8" type="submit" size="md" theme="primary">
        {buttonText}
      </Button>
    </Container>
  </section>
);

RequestDemoHero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
};

RequestDemoHero.defaultProps = {
  buttonText: 'Request demo',
};

export default RequestDemoHero;
